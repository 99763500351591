import React from "react"
import { Layout } from "../components"

const PrivacyPolicy = () => (
  <Layout>
    <div className="p-10">
      <h1 className="text-h1-mobile sm:text-h1-desktop font-cambon sm:mb-20">
        Datenschutzerklärung
      </h1>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Verantwortlich im Sinne der Datenschutzgesetzes:
      </h2>

      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Gorilla Funds UG, Hermann-Wellensiek-Str. 33, 67346 Speyer
      </h2>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">Datenschutz</h2>
      <p className="my-3">
        Als Webseitenbetreiber nehmen wir den Schutz aller persönlichen Daten
        sehr ernst. Alle personenbezogenen Informationen werden vertraulich und
        gemäß den gesetzlichen Vorschriften behandelt, wie in dieser
        Datenschutzerklärung erläutert. Unserer Webseite kann selbstverständlich
        genutzt werden, ohne dass Sie persönliche Daten angeben. Wenn jedoch zu
        irgendeinem Zeitpunkt persönliche Daten wie z.B. Name, Adresse oder
        E-Mail abgefragt werden, wird dies auf freiwilliger Basis geschehen.
        Niemals werden von uns erhobene Daten ohne Ihre spezielle Genehmigung an
        Dritte weitergegeben. Datenübertragung im Internet, wie zum Beispiel
        über E-Mail, kann immer Sicherheitslücken aufweisen. Der komplette
        Schutz der Daten ist im Internet nicht möglich. Wir speichern beim Kauf
        eines MUZZ & the Bananafratclub Token das Herkunftsland, den Namen des
        interagierenden Wallet und die Transaktions-ID des Vorgang.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Auskunft, Löschung, Sperrung
      </h2>
      <p className="my-3">
        Zu jedem Zeitpunkt können Sie sich über die personenbezogenen Daten,
        deren Herkunft und Empfänger und den Nutzen der Datenverarbeitung
        informieren und unentgeltlich eine Korrektur, Sperrung oder Löschung
        dieser Daten verlangen. Bitte nutzen Sie dafür die im Impressum
        angegebenen Kontaktwege. Für weitere Fragen zum Thema stehen wir Ihnen
        ebenfalls jederzeit zur Verfügung.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Server-Log-Files
      </h2>
      <p className="my-3">
        Der Seiten-Provider erhebt und speichert automatisch Daten in Server-Log
        Files, die von Ihrem Browser an uns übermittelt werden. Diese Daten
        enthalten:
        <br />
        – Browsertyp/ Browserversion
        <br />
        – Betriebssystem des Rechners
        <br />
        – Referrer URL
        <br /> – Hostname des zugreifenden Rechners
        <br />
        – Uhrzeit der Serveranfrage
        <br /> Diese Daten sind nicht personenbezogen. Es erfolgt keine
        Zusammenführung dieser Daten mit anderen Datenquellen. Wenn uns konkrete
        Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden behalten wir
        uns das Recht vor, diese Daten nachträglich zu überprüfen.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">Cookies</h2>
      <p className="my-3">Wir verwenden keine Cookies.</p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Änderung der Datenschutzbestimmungen
      </h2>
      <p className="my-3">
        Unsere Datenschutzerklärung können in unregelmäßigen Abständen angepasst
        werden, damit sie den aktuellen rechtlichen Anforderungen entsprechen
        oder um Änderungen unserer Dienstleistungen umzusetzen, z. B. bei der
        Einfügung neuer Angebote. Für Ihren nächsten Besuch gilt dann
        automatisch die neue Datenschutzerklärung.
      </p>
      <p className="my-3">
        Für Fragen zum Datenschutz schicken Sie uns bitte eine Nachricht an
        info@gorillashops.io mit dem Betreff „Datenschutz“.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Data protection
      </h2>

      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Responsible in terms of data protection law:
      </h2>

      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Gorilla Funds UG, Hermann-Wellensiek-Str. 33, 67346 Speyer
      </h2>

      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">Privacy</h2>

      <p className="my-3">
        As website operators, we take the protection of all personal data very
        seriously. All personal information will be treated confidentially and
        in accordance with legal regulations, as explained in this data
        protection declaration. Our website can, of course, be used without you
        providing any personal data. However, if at any time personal
        information such as name, address or email is requested, this will be
        done on a voluntary basis. Data collected by us will never be passed on
        to third parties without your special permission. Data transmission on
        the Internet, such as via email, can always have security gaps. Complete
        protection of data is not possible on the Internet. When purchasing a
        MUZZ & the Bananafratclub token, we store the country of origin, the
        name of the interacting wallet, and the transaction ID of the
        transaction.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Information, deletion, blocking
      </h2>
      <p className="my-3">
        At any time, you can find out about the personal data, their origin and
        recipients, and the use of the data processing and request a correction,
        blocking, or deletion of this data free of charge. Please use the
        contact channels given in the imprint. We are also available at any time
        for further questions on the subject.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Server log files
      </h2>
      <p className="my-3">
        The website provider automatically collects and stores data in server
        log files that are transmitted to us by your browser. This data
        includes: – Browser type/browser version
        <br />
        – Operating system of the computer
        <br />
        – Referrer URL
        <br />
        – Hostname of the accessing computer
        <br />
        – Time of server request
        <br />
        These data are not personal. This data is not merged with other data
        sources. If we become aware of specific indications of illegal use, we
        reserve the right to subsequently check this data.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">Cookies</h2>

      <p className="my-3">We don&#39;t use cookies.</p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Change in Privacy Policy
      </h2>
      <p className="my-3">
        Our data protection declaration can be adjusted at irregular intervals
        to comply with current legal requirements or to implement changes to our
        services, e.g., B., when inserting new offers. The new data protection
        declaration will then automatically apply to your next visit.
      </p>
      <p className="my-3">
        For questions about data protection, please send us a message to
        info@gorillashops.io with the subject &quot;Privacy.&quot;
      </p>
    </div>
  </Layout>
)

export default PrivacyPolicy
